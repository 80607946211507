import { BoxProps, Flex } from '@chakra-ui/react';
import classNames from 'classnames';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './NavMenu.module.scss';

const Links = [
  { label: 'Home', link: '/' },
  { label: 'About', link: '/about' },
  { label: 'V3X', link: 'https://v3x.pro' },
  { label: 'Genesis Node', link: 'https://node.d3x.xyz/' }
  // { name: 'Contact', link: '/contact' }
];

const NavMenu = (props: BoxProps) => {
  return (
    <Flex alignItems="center" gap={'15px'} fontWeight={600} flexDir={['column', null, 'row']} {...props}>
      {Links.map((item) =>
        item.link.startsWith('https') ? (
          <a href={item.link} target="_blank" rel="noopener noreferrer" className={styles.link}>
            {item.label}
          </a>
        ) : (
          <NavLink
            key={item.label}
            to={item.link}
            className={({ isActive }) => classNames(styles.link, { [styles.isActive]: isActive })}>
            {item.label}
          </NavLink>
        )
      )}
    </Flex>
  );
};

export default NavMenu;
